<template>
  <div class="research_course_warp">
    <FrameHeader frame="config" />
    <div class="research_course">
      <iframe :ref="uuid" :src="iframeSrc" class="research_course_iframe"></iframe>
    </div>
  </div>
</template>

<script>
import {util} from '@/libs'
import FrameHeader from "./components/FrameHeader.vue";
export default {
  components: { FrameHeader },
  data() {
    return {
      uuid: util.uuid(),
      iframeSrc: `http://edit.epub.xingyaedu.cn?token_authserver=${localStorage.getItem('token_authserver')}`
    }
  }
};
</script>

<style lang="less" scoped>
.research_course_warp {
  width: 100%;
  height: 100%;
  position: fixed;
  color: #333;
  overflow: hidden;
  .research_course {
    width: 100%;
    height: calc(100% - 60px);
    height: 100%;
    margin-top: 60px;
    // padding-top: 10px;
    .research_course_iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
</style>
